import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ minLoadingTime = 5000 }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, minLoadingTime);

    return () => clearTimeout(timer);
  }, [minLoadingTime]);

  if (!isLoading) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
      <div className="animate-bounce">
        <img src="/Profile-Image-Light-removebg-preview.png" alt="Logo" className="w-32 h-32" />
      </div>
    </div>
  );
};

export default LoadingScreen;