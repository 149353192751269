import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { main } from '../Style';

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex', alignItems:'center',justifyContent:'center' }} mt={20}>
      <CircularProgress sx={{color:main}} />
    </Box>
  );
}