import React, { lazy, useEffect, Suspense, useState, createContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import checkAuth from './app/auth';
import initializeApp from './app/init';
import LoadingScreen from './Loading';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { cover, darkcover } from './Style';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const SetPassword = lazy(() => import('./pages/SetPassword'));
const Register = lazy(() => import('./pages/Register'));
const Worng = lazy(() => import('./pages/404'));
const Expire = lazy(() => import('./Expire'));

initializeApp();

export const ThemeContext = createContext();

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = checkAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/404" replace />;
  }

  return children;
};

function App() {
  const [mode, setMode] = useState(() => {
    return localStorage.getItem('themeMode') || 'light';
  });

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          background: {
            default: mode === 'light' ? cover : darkcover,
          },
        },
      }),
    [mode],
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('themeMode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<LoadingScreen minLoadingTime={5000} />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/set-password/:token" element={<SetPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="/404" element={<Worng />} />
              <Route path="/lien-expire" element={<Expire />} />
              <Route
                path="/*"
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;

///////////////////////////////////dark mode////////////////////////////////////
// import React, { lazy, useEffect, Suspense } from 'react';
// import './App.css';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { themeChange } from 'theme-change';
// import checkAuth from './app/auth';
// import initializeApp from './app/init';
// import LoadingScreen from './Loading';
// import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import MUI components

// const Layout = lazy(() => import('./containers/Layout'));
// const Login = lazy(() => import('./pages/Login'));
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
// const ResetPassword = lazy(() => import('./pages/ResetPassword'));
// const SetPassword = lazy(() => import('./pages/SetPassword'));
// const Register = lazy(() => import('./pages/Register'));
// const Worng = lazy(() => import('./pages/404'));
// const Expire = lazy(() => import('./Expire'));

// initializeApp();

// // Create a dark theme
// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark', // Set the mode to 'dark'
//     primary: {
//       main: '#90caf9',
//     },
//     secondary: {
//       main: '#f48fb1',
//     },
//   },
// });

// // Protected Route component
// const ProtectedRoute = ({ children }) => {
//   const isAuthenticated = checkAuth();
  
//   if (!isAuthenticated) {
//     return <Navigate to="/404" replace />;
//   }

//   return children;
// };

// function App() {
//   useEffect(() => {
//     themeChange(false);
//   }, []);

//   return (
//     <ThemeProvider theme={darkTheme}> {/* Wrap your app in ThemeProvider */}
//       <Router>
//         <Suspense fallback={<LoadingScreen minLoadingTime={5000} />}>
//           <Routes>
//             <Route path="/login" element={<Login />} />
//             <Route path="/forgot-password" element={<ForgotPassword />} />
//             <Route path="/reset-password/:token" element={<ResetPassword />} />
//             <Route path="/set-password/:token" element={<SetPassword />} />
//             <Route path="/register" element={<Register />} />
//             <Route path="/404" element={<Worng />} />
//             <Route path="/lien-expire" element={<Expire />} />
//             <Route
//               path="/*"
//               element={
//                 <ProtectedRoute>
//                   <Layout />
//                 </ProtectedRoute>
//               }
//             />
//             <Route path="*" element={<Navigate to="/404" replace />} />
//           </Routes>
//         </Suspense>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;

//////////////////////fichier//////////////////////////////
// import React, { useState } from 'react';
// import axios from 'axios';

// const App = () => {
//     const [file, setFile] = useState(null);
//     const [text, setText] = useState('');

//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//     };

//     const handleUpload = async () => {
//         if (!file) {
//             alert('Please select a file to upload.');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('file', file);

//         try {
//             const response = await axios.post('http://localhost:3001/upload', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             setText(response.data.text);
//         } catch (error) {
//             console.error("Error uploading file:", error);
//         }
//     };

//     return (
//         <div>
//             <h1>File Upload and Text Extraction</h1>
//             <input type="file" onChange={handleFileChange} />
//             <button onClick={handleUpload}>Upload</button>
//             <div>
//                 <h2>Extracted Text:</h2>
//                 <pre>{text}</pre>
//             </div>
//         </div>
//     );
// };

// export default App;


//////////////////////image//////////////////////////////

// import React, { useState } from 'react';
// import axios from 'axios';

// const App = () => {
//     const [file, setFile] = useState(null);
//     const [items, setItems] = useState([]);

//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//     };

//     const handleUpload = async () => {
//         if (!file) {
//             alert('Please select a file to upload.');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('file', file);

//         try {
//             const response = await axios.post('http://localhost:3001/upload', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             setItems(response.data.items);
//         } catch (error) {
//             console.error("Error uploading file:", error);
//         }
//     };

//     return (
//         <div>
//             <h1>Invoice Upload and Data Extraction</h1>
//             <input type="file" accept="image/*" onChange={handleFileChange} />
//             <button onClick={handleUpload}>Upload</button>
//             <div>
//                 <h2>Extracted Invoice Data:</h2>
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>Item</th>
//                             <th>Quantity</th>
//                             <th>Unit Price</th>
//                             <th>Total</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {items.map((item, index) => (
//                             <tr key={index}>
//                                 <td>{item.item}</td>
//                                 <td>{item.quantity}</td>
//                                 <td>${item.unitPrice.toFixed(2)}</td>
//                                 <td>${item.total.toFixed(2)}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// };

// export default App;

// src/InvoiceUploader.js

// import React, { useState } from 'react';
// import axios from 'axios';

// const InvoiceUploader = () => {
//     const [file, setFile] = useState(null);
//     const [result, setResult] = useState(null);
//     const [ocrResult, setOcrResult] = useState('');

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!file) return;

//     console.log('File to upload:', file); // Log the file to be uploaded

//     const formData = new FormData();
//     formData.append('invoice', file);

//     try {
//         const response = await axios.post('http://localhost:3001/upload', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             },
//         });
//         console.log('Response:', response); // Log the server response
//         setResult(response.data.text);
//     } catch (error) {
//         console.error('Error uploading the file:', error);
//         setResult('Error processing the image.');
//     }
// };

//     const renderTable = (data) => {
//         // Process the invoice data to extract relevant fields
//         const invoiceData = data.split('\n').reduce((acc, line) => {
//             const [key, ...value] = line.split(':');
//             acc[key.trim()] = value.join(':').trim();
//             return acc;
//         }, {});

//         return (
//             <table border="1" style={{ width: '100%', marginTop: '20px' }}>
//                 <thead>
//                     <tr>
//                         <th>Champ</th>
//                         <th>Détails</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {Object.entries(invoiceData).map(([key, value]) => (
//                         <tr key={key}>
//                             <td>{key}</td>
//                             <td>{value}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         );
//     };

//     return (
//         <div style={{ padding: '20px' }}>
//             <h1>Uploader une Facture</h1>
//             <form onSubmit={handleSubmit}>
//                 <input type="file" onChange={handleFileChange} accept="image/*" required />
//                 <button type="submit">Télécharger</button>
//             </form>
//             {ocrResult && (
//                 <>
//                     <h2>Résultat OCR:</h2>
//                     {renderTable(ocrResult)}
//                 </>
//             )}
//         </div>
//     );
// };

// export default InvoiceUploader;
