export const main = '#4ab3ac';
export const white = '#fff';
export const black = '#000';
export const shadow = '#b9b9b948';
export const transparent = 'transparent';
export const clairGrey = '#d9e0ea';
export const invisible = '#ACBBD0';
export const darkGrey = '#5b6275';
export const green = 'green';
export const cover = '#fbfcfe';
export const darkcover = '#22222a';
export const mainShadow = '#4ab3ac2a';
export const red = '#db3333';
export const redShadow = '#db33332c';
export const darkblue = '#0a1c4b';
export const lightgreen = '#81c57b';
export const darkgreen = '#4ab3ac';
export const lightblue = '#416594';
export const tableheader = '#d9e0ea';
export const lightgrey = '#ecf0f5';
export const purple='#8f77e0';
export const purpleShadow='#ece9f9';
export const gradiant = `
  background: hsla(115, 39%, 63%, 1);
  background: linear-gradient(360deg, hsla(115, 39%, 63%, 1) 22%, hsla(176, 42%, 50%, 1) 100%);
  background: -moz-linear-gradient(360deg, hsla(115, 39%, 63%, 1) 22%, hsla(176, 42%, 50%, 1) 100%);
  background: -webkit-linear-gradient(360deg, hsla(115, 39%, 63%, 1) 22%, hsla(176, 42%, 50%, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#81c57b", endColorstr="#4ab3ac", GradientType=1);
`;
