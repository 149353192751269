// import React, { createContext, useState, useContext } from 'react';

// const IdContext = createContext();

// export const IdProvider = ({ children }) => {
//   const [ExoID, setExoID] = useState(null);

//   return (
//     <IdContext.Provider value={{ ExoID, setExoID }}>
//       {children}
//     </IdContext.Provider>
//   );
// };

// export const useId = () => {
//   return useContext(IdContext);
// };
import React, { createContext, useState, useContext } from 'react';

const IdContext = createContext();

export const IdProvider = ({ children }) => {
   const [ExoID, setExoID] = useState(null);
   const [selectedAnnexID, setSelectedAnnexID] = useState(null);

   return (
     <IdContext.Provider value={{ 
       ExoID, 
       setExoID, 
       selectedAnnexID, 
       setSelectedAnnexID 
     }}>
       {children}
     </IdContext.Provider>
   );
};

export const useId = () => {
  return useContext(IdContext);
};

export const useIdContext = () => useContext(IdContext);
